<template>
  <div class="search main">
    <div class="searchst">
      <ul class="searchstoul" v-if="paperList.length > 0">
        <li v-for="(item, index) in paperList" :key="index">
          <div class="searchstouldiv" @click="searchTo(item)">

             <i> [{{
                  item.rtype == 1
                    ? "单选题"
                    : item.rtype == 2
                    ? "多选题"
                    : item.rtype == 3
                    ? "不定项"
                    : item.rtype == 4
                    ? "论述题"
                    : item.rtype == 5
                    ? "判断题"
                    : ""
               }}]</i>

              <div v-html="item.title"></div>

          </div>
        </li>
      </ul>
      <div
        class="studyLogdivmore"
        @click="studyLogdivmore()"
        v-if="paperList.length > 0"
      >
        <span> <i class="iconfont icon-more_btn"></i>{{ btnMessage }} </span>
      </div>
      <div class="studyLogdivwsj" v-if="paperList.length == 0">
        <div class="studyCoursekedetailswkc">
          <img src="./assets/images/study_zw.png" alt="" />
          <p>暂无搜索结果</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { searchquestion } from "./assets/api";
export default {
  name: "search",
  data() {
    return {
      title: "",
      page: 1,
      paperList: [],
      btnMessage: "加载更多",
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        this.title = val.query.searchText;
        this.paperList = [];
        this.page = 1;
        this.searchquestion();
      },
    },
  },
  mounted() {
    if (
      this.$route.query.searchText != undefined ||
      this.$route.query.searchText != ""
    ) {
      this.paperList = [];
      this.page = 1;
      this.title = this.$route.query.searchText;
      this.searchquestion();
    }
  },
  methods: {
    searchTo(data) {
      this.$router.push({
        path: "/pape_doRecite",
        query: {
          question_id: data.question_id,
          title: "试题搜索",
        },
      });
    },
    searchquestion() {
      let par = {
        title: this.title,
        page: this.page,
      };
      searchquestion(par).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (this.paperList == 0) {
              this.paperList = res.data;
            } else {
              this.paperList = this.paperList.concat(res.data);
            }
          } else {
            this.btnMessage = "暂无更多";
          }
        }
      });
    },
    //试卷练习
    studyLogdivmore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.searchquestion();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./assets/tiku_search";
</style>
